import cn from 'classnames';
import { useRouter } from 'next/router';
import { Button } from './Button';
import { useFirebaseAuth, useFirebaseAuthState } from './FirebaseProvider';

export function Header() {
  return (
    <header>
      <Nav />
      <div className="mx-auto lg:max-w-240 xl:max-w-313">
        <a href="/" className={cn('block', 'w-43.5 sm:w-51 lg:w-73.5 xl:w-86', 'mx-auto lg:ml-86 xl:ml-107')}>
          <h1 className="w-full">
            <img src="/images/logo.svg" alt="Choosy" />
          </h1>
        </a>
      </div>
    </header>
  );
}

type NavProps = {
  readonly className?: string;
};

function Nav({ className }: NavProps) {
  const { signOut } = useFirebaseAuth();
  const { loggedIn } = useFirebaseAuthState();
  const router = useRouter();

  return (
    <nav className={cn('flex items-center justify-end h-18.25 sm:h-21.25 lg:h-13.5 xl:h-16 px-6', className)}>
      <ul className="flex items-center space-x-6">
        {loggedIn ? (
          <li>
            <Button size="md" theme="secondary" onClick={() => signOut().then(() => router.push('/'))}>
              Abmelden
            </Button>
          </li>
        ) : null}
      </ul>
    </nav>
  );
}
