import { useCallback } from 'react';
import { StringifiableRecord, stringifyUrl } from 'query-string';
import { useFirebaseAuthState } from '../components/FirebaseProvider';

const API_ENDPOINT = process.env.NEXT_PUBLIC_CHOOSY_API_URL;

export type ApiRequestInit = RequestInit & {
  readonly json?: any;
  readonly query?: StringifiableRecord;
  readonly autofail?: boolean;
  readonly idToken?: (() => Promise<string | undefined> | string | undefined) | string;
};

export type ApiFetch = (url: string, init?: ApiRequestInit) => Promise<Response>;

type ApiClient = {
  readonly fetch: ApiFetch;
};

export function useApiClient(): ApiClient {
  const { user } = useFirebaseAuthState();

  return {
    fetch: useCallback(
      async (url: string, init: ApiRequestInit = {}) => {
        return apiFetch(url, {
          idToken: async () => {
            return user ? await user.getIdToken() : undefined;
          },
          ...init,
        });
      },
      [user],
    ),
  };
}

export async function apiFetch(url: string, { query, json, autofail = true, idToken, ...init }: ApiRequestInit = {}) {
  const resolvedIdToken = typeof idToken === 'function' ? await idToken() : idToken;

  const res = await fetch(buildApiUrl(url, query), {
    ...init,
    headers: {
      Accept: 'application/json',
      ...init?.headers,
      ...(resolvedIdToken ? { Authorization: `Bearer ${resolvedIdToken}` } : {}),
      ...(json ? { 'Content-Type': `application/json` } : {}),
    },
    body: json ? JSON.stringify(json) : init.body,
  });

  if (res.status === 401) {
    throw new Error(`request failed with status ${res.status}`);
  }

  if (autofail && !res.ok) {
    throw new Error(`request failed with status ${res.status}`);
  }

  return res;
}

function buildApiUrl(urlOrPartial: string, query: StringifiableRecord | undefined) {
  const url = urlOrPartial.startsWith('http') ? urlOrPartial : new URL(urlOrPartial, API_ENDPOINT).toString();
  return stringifyUrl({ url: url.toString(), query }, { arrayFormat: 'bracket' });
}
