import React, { AnchorHTMLAttributes, forwardRef, HTMLAttributes, SVGAttributes } from 'react';
import cn from 'classnames';

export function Footer() {
  return (
    <footer className="px-6 sm:px-6 py-10 sm:py-20 bg-choosy-gray-dark text-white">
      <div className="flex flex-col md:flex-row md:justify-between mx-auto max-w-6xl space-y-16 md:space-y-0">
        <nav>
          <Heading>Rechtliches</Heading>
          <ul className="mt-4">
            <li>
              <NavLink href={`${process.env.NEXT_PUBLIC_CHOOSY_WEBSITE_URL}/legal-notice`}>Impressum</NavLink>
            </li>
            <li>
              <NavLink href={`${process.env.NEXT_PUBLIC_CHOOSY_WEBSITE_URL}/privacy-policy`}>Datenschutz</NavLink>
            </li>
            <li>
              <NavLink href={`${process.env.NEXT_PUBLIC_CHOOSY_WEBSITE_URL}/terms-conditions`}>
                Nutzungsbedingungen
              </NavLink>
            </li>
          </ul>
        </nav>
        <nav>
          <Heading>Unternehmen</Heading>
          <ul className="mt-4">
            <li>
              <NavLink href={`${process.env.NEXT_PUBLIC_CHOOSY_WEBSITE_URL}/blog`}>Blog</NavLink>
            </li>
            <li>
              <NavLink href={`${process.env.NEXT_PUBLIC_CHOOSY_WEBSITE_URL}/jobs`}>Jobs</NavLink>
            </li>
          </ul>
        </nav>
        <div>
          <Heading>Social Media</Heading>
          <ul className="mt-4 flex space-x-4">
            <li>
              <NavLink href="https://www.facebook.com/ChoosyMealPlanning" target="_blank">
                <IconFacebook className="w-8 sm:w-10 lg:w-12 xl:w-14" />
              </NavLink>
            </li>
            <li>
              <NavLink href="https://twitter.com/Choosy_App" target="_blank">
                <IconTwitter className="w-8 sm:w-10 lg:w-12 xl:w-14" />
              </NavLink>
            </li>
            <li>
              <NavLink href="https://www.instagram.com/choosymealplan" target="_blank">
                <IconInstagram className="w-8 sm:w-10 lg:w-12 xl:w-14" />
              </NavLink>
            </li>
          </ul>
        </div>
        <div>
          <img src="/images/logo-bright.svg" className="w-40 sm:w-44 lg:w-50 xl:w-56" alt="Choosy" />
          <div>© 2020-2022 Choosy</div>
        </div>
      </div>
    </footer>
  );
}

const Heading = forwardRef<HTMLSpanElement, HTMLAttributes<HTMLSpanElement>>(({ className, ...props }, ref) => (
  <span
    {...props}
    ref={ref}
    className={cn('border-b-2 border-choosy-green-dark-300 text-20px font-semibold', className)}
  />
));

const NavLink = forwardRef<HTMLAnchorElement, AnchorHTMLAttributes<HTMLAnchorElement>>(
  ({ className, ...props }, ref) => (
    <a
      {...props}
      ref={ref}
      className={cn('transition-colors duration-150 text-white text-16px hover:text-choosy-green-dark-300', className)}
    />
  ),
);

const IconFacebook = forwardRef<SVGSVGElement, SVGAttributes<SVGSVGElement>>(
  ({ className, viewBox = '0 0 57 57', fill = 'currentColor', ...props }, ref) => (
    <svg {...props} ref={ref} className={cn(className)} viewBox={viewBox} fill={fill}>
      <title>Choosy auf Facebook</title>
      <path
        d="M28.5002 0C12.7601 0 0 12.8135 0 28.6193C0 42.7953 10.2749 54.5354 23.7469 56.8088V34.5901H16.8718V26.5945H23.7469V20.6988C23.7469 13.8582 27.9076 10.1304 33.9854 10.1304C36.8963 10.1304 39.3979 10.3482 40.1241 10.4441V17.5945L35.9087 17.5965C32.6041 17.5965 31.9669 19.1731 31.9669 21.4874V26.5904H39.8521L38.8237 34.586H31.9669V57C46.068 55.2766 57 43.2374 57 28.6111C57 12.8135 44.2399 0 28.5002 0Z"
        fill="currentColor"
      />
    </svg>
  ),
);

const IconTwitter = forwardRef<SVGSVGElement, SVGAttributes<SVGSVGElement>>(
  ({ className, viewBox = '0 0 57 57', fill = 'currentColor', ...props }, ref) => (
    <svg {...props} ref={ref} className={cn(className)} viewBox={viewBox} fill={fill}>
      <title>Choosy auf Twitter</title>
      <path
        d="M28.5 0C12.7623 0 0 12.7623 0 28.5C0 44.2377 12.7623 57 28.5 57C44.2377 57 57 44.2377 57 28.5C57 12.7623 44.2377 0 28.5 0ZM41.5128 22.2213C41.5254 22.5018 41.5315 22.7836 41.5315 23.0667C41.5315 31.7107 34.9518 41.6785 22.9192 41.6789H22.9197H22.9192C19.225 41.6789 15.7873 40.5961 12.8923 38.7404C13.4042 38.8009 13.9251 38.8309 14.4527 38.8309C17.5177 38.8309 20.3383 37.7855 22.5774 36.0307C19.7138 35.9777 17.2993 34.0864 16.4661 31.4872C16.8649 31.5637 17.275 31.6054 17.6955 31.6054C18.2926 31.6054 18.871 31.525 19.4207 31.375C16.4274 30.7757 14.1726 28.1304 14.1726 24.9627C14.1726 24.9332 14.1726 24.9066 14.1735 24.8792C15.055 25.3693 16.063 25.6642 17.1363 25.6972C15.3798 24.5252 14.2252 22.5218 14.2252 20.2522C14.2252 19.0536 14.5492 17.9308 15.1111 16.9641C18.337 20.9223 23.158 23.5255 28.5948 23.799C28.4826 23.3198 28.4248 22.8205 28.4248 22.3074C28.4248 18.6962 31.3545 15.7664 34.967 15.7664C36.8487 15.7664 38.5482 16.5618 39.742 17.8334C41.2323 17.5394 42.6317 16.9949 43.8959 16.2456C43.4067 17.7725 42.3699 19.0536 41.0192 19.8638C42.3425 19.7055 43.6037 19.3546 44.7757 18.8336C43.9002 20.1456 42.79 21.298 41.5128 22.2213V22.2213Z"
        fill="currentColor"
      />
    </svg>
  ),
);

const IconInstagram = forwardRef<SVGSVGElement, SVGAttributes<SVGSVGElement>>(
  ({ className, viewBox = '0 0 57 57', fill = 'currentColor', ...props }, ref) => (
    <svg {...props} ref={ref} className={cn(className)} viewBox={viewBox} fill={fill}>
      <title>Choosy auf Instagram</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.5 57C44.2401 57 57 44.2401 57 28.5C57 12.7599 44.2401 0 28.5 0C12.7599 0 0 12.7599 0 28.5C0 44.2401 12.7599 57 28.5 57ZM20.6584 10H36.3408C41.6669 10 45.9999 14.5806 45.9997 20.2106V36.7891C45.9997 42.4191 41.6669 46.9997 36.3408 46.9997H20.6584C15.3328 46.9997 11 42.4193 11 36.7891V20.2106C11 14.5806 15.3328 10 20.6584 10ZM36.341 43.7169C39.9546 43.7169 42.8945 40.6092 42.8945 36.7891H42.8943V20.2106C42.8943 16.3907 39.9544 13.2828 36.3408 13.2828H20.6584C17.0451 13.2828 14.1053 16.3907 14.1053 20.2106V36.7891C14.1053 40.6092 17.0451 43.7171 20.6584 43.7169H36.341ZM28.4999 18.9665C23.527 18.9665 19.4814 23.2433 19.4814 28.5004C19.4814 33.7572 23.527 38.0338 28.4999 38.0338C33.4728 38.0338 37.5184 33.7572 37.5184 28.5004C37.5184 23.2433 33.4728 18.9665 28.4999 18.9665ZM28.4999 34.7508C25.2395 34.7508 22.5867 31.9469 22.5867 28.5001C22.5867 25.0532 25.2393 22.2491 28.4999 22.2491C31.7605 22.2491 34.4131 25.0532 34.4131 28.5001C34.4131 31.9469 31.7603 34.7508 28.4999 34.7508ZM36.2881 16.8876C36.7106 16.4389 37.2984 16.1829 37.8967 16.1829C38.497 16.1829 39.085 16.4389 39.5073 16.8876C39.9317 17.334 40.1739 17.9556 40.1739 18.5903C40.1739 19.2227 39.9317 19.8443 39.5073 20.2929C39.0829 20.7394 38.497 20.9976 37.8967 20.9976C37.2984 20.9976 36.7104 20.7394 36.2881 20.2929C35.8637 19.8443 35.6194 19.223 35.6194 18.5903C35.6194 17.9556 35.8635 17.334 36.2881 16.8876Z"
        fill="currentColor"
      />
    </svg>
  ),
);
